<template>
  <div
    ref="modalbg"
    class="z-30 center w-full h-full lg:px-20 lg:py-10 bg-black/50 text-center"
  >
    <div
      class="bg-white w-full lg:w-2/3 inline-block h-full lg:rounded-3xl overflow-y-auto p-8 basis-0 relative text-center"
    >
      <div
        v-if="showAIPopup"
        class="fixed center border rounded-lg pt-4 px-4 pb-4 w-96 bg-white shadow-xl text-left"
      >
        <span class="font-bold text-2xl">KI-Generator</span><br />
        <div
          @click="showAIPopup = false"
          class="iconborder-modal z-40 cursor-pointer float-right"
        >
          <font-awesome-icon size="lg" icon="xmark" class="inborder" />
        </div>
        <label>URL zur Stellenbeschreibung:</label>
        <input
          v-model="joburl"
          class="w-full"
          placeholder="https://stepstone.de/j/gh53zafc2"
        />
        <span class="text-red-500" v-if="kiloading">Die Generierung kann bis zu 60 Sekunden dauern.</span>
        <div class="w-full text-right">
          <button
            class="mt-2 relative"
            @click="generateCoverText()"
            v-show="joburl.length > 10"
          >
            <div v-if="kiloading" class="lds-dual-ring"></div>
            <span v-bind:class="[kiloading ? 'text-white' : '']"
              >Jetzt generieren</span
            >
          </button>
        </div>
      </div>
      <div class="h-full w-full text-left">
        <div
          @click="$emit('closeModal')"
          class="iconborder-modal z-40 cursor-pointer"
        >
          <font-awesome-icon size="lg" icon="xmark" class="inborder" />
        </div>
        <div v-if="loading" class="inline-block lds-dual-ring"></div>
        <div v-if="loading" class="center mt-10 text-black">Lade Daten...</div>
        <div v-if="!loading">
          <div class="w-full text-center pb-3">
            <input
              class="w-52 inline-block px-2 py-1 text-xl"
              v-model="cover.documentTitle"
            /><span class="text-xl font-bold">.pdf</span>
            <span class="ml-3 inline-block font-bold text-2xl mt-0">{{
              title
            }}</span>
          </div>
          <div class="">
            <span class="font-bold text-2xl">Persönliche Infos</span>
            <div class="grid lg:grid-cols-2">
              <div class="col-span-2">
                <span class="block font-semibold">Vor- und Nachname</span>
                <input
                  v-model="cover.name"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="Vor- und Nachname"
                />
              </div>
              <div class="mt-6">
                <span class="block font-semibold">E-Mail</span>
                <input
                  v-model="cover.mail"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="E-Mail"
                />
              </div>
              <div class="mt-6">
                <span class="block font-semibold">Telefonnummer</span>
                <input
                  v-model="cover.phone"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="Telefonnummer"
                />
              </div>
            </div>
            <span class="font-bold text-2xl mt-5 inline-block"
              >Eigene Anschrift</span
            >
            <div class="">
              <div class="mt-6 col-span-3">
                <span class="block font-semibold">Straße</span>
                <input
                  class="w-afull bg-gray-100 p-3 mt-2"
                  v-model="cover.street"
                  placeholder="Musterstraße 1"
                />
              </div>
            </div>
            <div class="grid grid-cols-3">
              <div class="mt-6">
                <span class="block font-semibold">PLZ</span>
                <input
                  v-model="cover.zip"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="PLZ"
                />
              </div>
              <div class="mt-6 col-span-2">
                <span class="block font-semibold">Stadt.</span>
                <input
                  v-model="cover.city"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="Stadt"
                />
              </div>
            </div>
            <span class="font-bold text-2xl mt-5 inline-block"
              >Unternehmensanschrift</span
            >
            <div class="">
              <div class="mt-6 col-span-3">
                <span class="block font-semibold">Unternehmensname</span>
                <input
                  class="w-afull bg-gray-100 p-3 mt-2"
                  v-model="cover.companyName"
                  placeholder="Muster AG"
                />
              </div>
            </div>
            <div class="">
              <div class="mt-6 col-span-3">
                <span class="block font-semibold"
                  >Ansprechpartner (wenn bekannt)</span
                >
                <input
                  class="w-afull bg-gray-100 p-3 mt-2"
                  v-model="cover.companyPerson"
                  placeholder="Frau XX"
                />
              </div>
            </div>
            <div class="">
              <div class="mt-6 col-span-3">
                <span class="block font-semibold">Straße</span>
                <input
                  class="w-afull bg-gray-100 p-3 mt-2"
                  v-model="cover.companyStreet"
                  placeholder="Musterstraße 1"
                />
              </div>
            </div>
            <div class="grid grid-cols-3">
              <div class="mt-6">
                <span class="block font-semibold">PLZ</span>
                <input
                  v-model="cover.companyZip"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="PLZ"
                />
              </div>
              <div class="mt-6 col-span-2">
                <span class="block font-semibold">Stadt.</span>
                <input
                  v-model="cover.companyCity"
                  class="w-11/12 bg-gray-100 p-3 mt-2"
                  placeholder="Stadt"
                />
              </div>
            </div>
            <span class="font-bold text-2xl mt-5 inline-block"
              >Anschreiben</span
            >
            <div class="">
              <div class="">
                <span class="block font-semibold">Datum: </span>
                <input
                  type="date"
                  class="w-auto bg-gray-100 p-3 mt-2"
                  v-model="cover.date"
                  :placeholder="new Date().toISOString().split('T')[0]"
                />
              </div>
            </div>
            <div class="col-span-3">
              <span class="block font-semibold">Betreff: </span>
              <input
                class="w-full bg-gray-100 p-3 mt-2"
                v-model="cover.subject"
                placeholder="Bewerbung als XXX"
              />
              <span class="inline-block font-semibold">Text: </span
              ><button class="ml-2 mt-2" @click="showAIPopup = true">
                BETA: Text generieren
              </button>
              <textarea
                class="w-full bg-gray-100 p-3 mt-2"
                v-model="cover.text"
                rows="10"
                placeholder="Bewerbung als XXX"
              ></textarea>
            </div>
          </div>
          <div class="w-full text-center py-5">
            <button
              v-if="id != 0"
              class="mx-2 text-red-500 border-red-500"
              @click="$emit('deleteCover', id)"
            >
              Löschen
            </button>
            <button class="mx-2" @click="showPreview()">Vorschau</button>
            <button
              class="mx-2 text-green-600 border-green-600"
              @click="saveCover()"
            >
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/API/Private";

export default {
  props: { id: Number, copyFrom: Number },
  expose: ["addFile"],
  emits: ["fileAdded", "closeModal", "fileSaved"],
  data() {
    return {
      title: " erstellen",
      loading: false,
      cover: {
        documentTitle: "Anschreiben",
      },
      eduIndex: 0,
      workIndex: 0,
      newStepType: "Auswählen",
      console: console,
      pictureName: "Klicken für Upload",
      interestInput: "",
      otherQualificationInput: "",
      languagelevel: 0,
      skilllevel: 0,
      newLanguage: "",
      newSkill: "",
      showAIPopup: false,
      joburl: "",
      kiloading: false 
    };
  },
  methods: {
    checkClosing(event) {
      if (event.target == this.$refs.modalbg) {
        this.$emit("closeModal");
      }
    },
    showPreview() {
      API.getCoverPreview(this.cover, (res) => {
        if (res.id) {
          window.open(
            process.env.VUE_APP_PRIVATE_URL + "/files/static/" + res.id
          );
        }
      });
    },
    generateCoverText() {
      if(this.kiloading)return;
      this.kiloading=true;
      API.generateCoverText(this.joburl, (res) => {
        this.kiloading=false;
        if (res.covertext) {
          this.cover.text = res.covertext;
        }
        this.joburl = "";
        this.showAIPopup = false;
      });
    },
    saveCover() {
      let vm = this;
      if (this.id != 0) {
        API.editCover(this.id, this.cover, (res) => {
          if (res) {
            vm.$emit("fileSaved");
          }
        });
      } else {
        API.saveCover(this.cover, (res) => {
          if (res) {
            vm.$emit("fileSaved");
          }
        });
      }
    },
  },
  computed: {},
  created: function () {
    if (this.id != 0) {
      this.loading = true;
      this.title = " bearbeiten";
      let vm = this;
      API.getCoverData(this.id, (data) => {
        vm.cover = data;

        vm.loading = false;
      });
    }
    if (this.copyFrom != 0) {
      this.loading = true;
      let vm = this;
      API.getCoverData(this.copyFrom, (data) => {
        vm.cover = data;
        vm.cover.id = 0;

        vm.loading = false;
      });
    }
  },
};
</script>

<style scoped>
.circle,
.smallcircle,
.smallcircle-selected,
.circle-selected {
  margin-top: 13px;
  margin-right: 10px;
  vertical-align: top;
  display: inline-block;
  width: 33px;
  height: 33px;
  padding: 3px;
  border: 4px solid #292f4c;
  border-radius: 20px;
  cursor: pointer;
}

.circle-selected,
.smallcircle-selected {
  background: #292f4c;
}

.smallcircle,
.smallcircle-selected {
  margin-top: 10px;
  margin-right: 4px;
  width: 12px;
  height: 12px;
  padding: 1px;
  border-radius: 10px;
  border: 2px solid #292f4c;
  cursor: default;
}

input[type="checkbox"] {
  padding: unset;
  width: unset;
}
</style>
