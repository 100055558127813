<template>
  <div class="w-full h-full relative min-w-0 overflow-y-auto p-4">
    <file-viewer
      v-if="fileViewerFile"
      :file="fileViewerFile"
      @closeFileViewer="fileViewerFile = false"
    />
    <Transition>
      <top-banner :mode="bannerMode" :text="bannerText" v-if="bannerText" />
    </Transition>
    <Transition>
      <GenerateCVModal
        v-if="showCVModal"
        
        @closeModal="showCVModal = false"
        @fileSaved="fileSaved"
        :id="cvId"
        :copyFrom="copyFrom"
      />
    </Transition>
    <Transition>
      <GenerateCoverModal
        v-if="showCoverModal"
        
        @closeModal="showCoverModal = false"
        @fileSaved="fileSaved"
        :id="coverId"
        :copyFrom="copyFrom"
      />
    </Transition>
    <Transition>
      <add-file-modal
        ref="addFileModal"
        v-if="showAddFileModal"
        :kind="addFileKind"
        @closeModal="showAddFileModal = false"
        @addFile="addFile"
        
      />
    </Transition>
    <span class="font-bold text-2xl">Deine Dokumente</span><br />

    <document-scroller
      :generate="true"
      :files="files.Anschreiben"
      category="Anschreiben"
      @viewFile="viewFile"
      @addFile="showFilePicker"
      @editFile="editFile"
      @copyFile="copyFile"
      @deletedFile="fileSaved"
      @generateDoc="
        coverId = 0;
        showCoverModal = true;
      "
    />
    <document-scroller
      :generate="true"
      :files="files.Lebenslauf"
      category="Lebenslauf"
      @viewFile="viewFile"
      @addFile="showFilePicker"
      @deletedFile="fileSaved"
      @copyFile="copyFile"
      @editFile="editFile"
      @generateDoc="
        cvId = 0;
        showCVModal = true;
      "
    />
    <document-scroller
      :files="files.Qualifikation"
      category="Qualifikation"
      @viewFile="viewFile"
      @addFile="showFilePicker"
      @deletedFile="fileSaved"
    />
    <document-scroller
      :files="files.Sonstiges"
      category="Sonstige Dokumente"
      @viewFile="viewFile"
      @addFile="showFilePicker"
      @deletedFile="fileSaved"
    />
  </div>
</template>

<script>
import AddFileModal from "@/components/AddFileModal.vue";
import TopBanner from "@/components/TopBanner.vue";
import API from "@/API/Private";
import DocumentScroller from "@/components/DocumentScroller.vue";
import FileViewer from "@/components/FileViewer.vue";
import GenerateCVModal from "@/components/GenerateCVModal.vue";
import GenerateCoverModal from "@/components/GenerateCoverModal.vue";
// @ is an alias to /src

export default {
  name: "TenantJobsView",
  components: {
    AddFileModal,
    TopBanner,
    DocumentScroller,
    FileViewer,
    GenerateCVModal,
    GenerateCoverModal,
  },
  props: {
    user: Object,
    slug: String,
      },
  data() {
    return {
      cvId: 0,
      coverId: 0,
      copyFrom: false,
      fileViewerFile: false,
      bannerText: "",
      bannerMode: "success",
      addFileKind: "Anschreiben",
      showAddFileModal: false,
      showCVModal: false,
      showCoverModal: false,
      files: {
        covers: [],
        cvs: [],
        qualifications: [],
        miscs: [],
      },
    };
  },

  methods: {
    editFile(file) {
      if (file.documentType == "Lebenslauf") {
        this.cvId = file.id;
        this.showCVModal = true;
      } else if (file.documentType == "Anschreiben") {
        this.coverId = file.id;
        this.showCoverModal = true;
      }
    },
    copyFile(file) {
      if (file.documentType == "Lebenslauf") {
        this.cvId = 0;
        this.copyFrom = file.id;
        this.showCVModal = true;
      } else if (file.documentType == "Anschreiben") {
        this.coverId = 0;
        this.copyFrom = file.id;
        this.showCoverModal = true;
      }
    },
    fileSaved() {
      this.showCVModal = false;
      this.showCoverModal = false;
      this.copyFrom = false;
      this.getFiles();
    },
    addFile(file) {
      this.showAddFileModal = false;
      let vm = this;
      if (!file.id) {
        API.uploadFile(file, () => {
          vm.getFiles()
        })
      }
    },
    viewFile(file) {
      let vm = this;
      API.getFile(file.id, (file) => {
        if (file) {
          vm.fileViewerFile = file;
        }
      });
    },
    showFilePicker(kind) {
      this.addFileKind = kind;
      this.showAddFileModal = true;
    },
    showBanner(text, error = false) {
      this.bannerMode = error ? "error" : "success";
      this.bannerText = text;
      let vm = this;
      setTimeout(() => {
        vm.bannerText = "";
      }, 5000);
    },
    getFiles() {
      API.getFiles((files) => {
        this.files=files
      });
    },
  },
  created: function () {
    this.getFiles();
  },
};
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 4s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
</style>