

function authPUT(path, content, callback) {
    authChange(path, "PUT", content, callback)
}

function authPOST(path, content, callback) {
    authChange(path, "POST", content, callback)
}

function authDelete(path, callback) {
    authChange(path, "DELETE", {}, callback)
}

function authChange(path, method, content, callback) {
    var req = new XMLHttpRequest()
    req.open(method, process.env.VUE_APP_PRIVATE_URL + path)
    req.setRequestHeader('content-type', 'application/json')
    req.withCredentials = true
    req.addEventListener('load', function (event) {
        try {
            callback(JSON.parse(req.responseText))
        } catch (e) {
            console.log(e)
            callback(false)
        }
    })
    req.send(JSON.stringify(content))
}

function authGET(path, callback) {
    var req = new XMLHttpRequest()
    req.open('GET', process.env.VUE_APP_PRIVATE_URL + path)
    req.withCredentials = true
    req.addEventListener('load', function (event) {
        if (req.status == 204) {
            callback(true)
        } else {
            try {
                callback(JSON.parse(req.responseText))
            } catch (e) {
                callback(false)
            }
        }
    })
    req.send()
}

function getTenantJobs(slug, callback) {
    return authGET("/jobs/" + slug, callback)
}

function getUser(callback) {
    return authGET("/user", callback)
}

function addApplication(application, callback) {
    return authPOST("/applications", application, callback)
}

function addComment(comment, callback) {
    return authPOST("/comments", comment, callback)
}

function updateApplication(id, update, callback) {
    return authPUT("/applications/" + id, update, callback)
}

function updateApplicationOrder(application, callback) {
    return authPUT("/applications/order", application, callback)
}

function uploadApplicationFile(appId, file, callback) {
    return authPOST("/applications/" + appId + "/files", file, callback)
}
function uploadFile(file, callback) {
    return authPOST("/files", file, callback)
}

function getCVPreview(cvdata, callback) {
    return authPOST("/generator/cv/preview", cvdata, callback)
}

function getCoverPreview(cvdata, callback) {
    return authPOST("/generator/cover/preview", cvdata, callback)
}

function saveCV(cvdata, callback) {
    return authPOST("/generator/cv", cvdata, callback)
}

function editCV(id, cvdata, callback) {
    return authPUT("/generator/cv/" + id, cvdata, callback)
}

function saveCover(cvdata, callback) {
    return authPOST("/generator/cover", cvdata, callback)
}

function editCover(id, cvdata, callback) {
    return authPUT("/generator/cover/" + id, cvdata, callback)
}

function deleteFile(id, callback) {
    return authDelete("/files/" + id, callback)
}
function deleteApplication(id, callback) {
    return authDelete("/applications/" + id, callback)
}

function getCompaniesWithJobs(callback) {
    return authGET("/jobs", callback)
}

function getApplications(callback) {
    return authGET("/applications", callback)
}

function getCVData(id, callback) {
    return authGET("/generator/cv/" + id, callback)
}

function getCoverData(id, callback) {
    return authGET("/generator/cover/" + id, callback)
}

function getFile(fileId, callback) {
    return authGET("/files/" + fileId, callback)
}

function getSearchEntries(slug, callback) {
    authGET("/search?slug="+slug, callback)
}

function getFiles(callback) {
    return authGET("/files", files => {
        fe = { "Dokument": files }
        if (files) {
            fe.Anschreiben = files.filter((el) => {
                return el.documentType == "Anschreiben";
            });
            fe.Lebenslauf = files.filter((el) => {
                return el.documentType == "Lebenslauf";
            });
            fe.Qualifikation = files.filter((el) => {
                return el.documentType == "Qualifikation";
            });
            fe.Sonstiges = files.filter((el) => {
                return el.documentType == "Sonstiges";
            });
        }
        callback(fe)
    })
}


function getCommentsForApplication(application, callback) {
    return authGET('/applications/' + application.id + "/comments", callback)
}

function addChatMessage(applicationId, message, cb){
    authPOST("/applications/" + applicationId + "/chat", message, cb)
}

function getChatMessages(applicationId, cb){
    authGET("/applications/" + applicationId + "/chat", cb)
}

function getChats(cb){
    authGET("/chats", cb)
}

function generateCoverText(joburl, cb){
    authPOST("/generator/cover/generateText", { joburl: joburl }, cb)
}


module.exports = {
    generateCoverText: generateCoverText,
    getChats: getChats,
    addChatMessage: addChatMessage,
    getChatMessages: getChatMessages,
    getSearchEntries: getSearchEntries,
    getFiles: getFiles,
    editCV: editCV,
    getCVData: getCVData,
    getCoverData: getCoverData,
    getFile: getFile,
    deleteFile: deleteFile,
    deleteApplication: deleteApplication,
    addComment: addComment,
    getCommentsForApplication: getCommentsForApplication,
    addApplication: addApplication,
    updateApplicationOrder: updateApplicationOrder,
    updateApplication: updateApplication,
    getCVPreview: getCVPreview,
    getCoverPreview: getCoverPreview,
    saveCV: saveCV,
    saveCover: saveCover,
    editCover: editCover,
    uploadFile: uploadFile,
    uploadApplicationFile: uploadApplicationFile,
    getTenantJobs: getTenantJobs,
    getCompaniesWithJobs: getCompaniesWithJobs,
    getApplications: getApplications,
    getUser: getUser

}