<template>
  <div class="mt-4">
    <span class="font-bold text-xl">{{ category }}</span
    ><br />
    <div class="w-full py-4 whitespace-nowrap overflow-x-auto">
      <div
        class="
          align-top
          scale-100
          inline-block
          w-52
          p-2
          rounded-xl
          relative
          shadow-selected
          bg-white
        "
        @click="addApplication = true"
      >
        <div class="w-full h-full text-center">
          <div class="mt-2">
            <div v-if="generate" class="inline-block mr-2">
              <font-awesome-icon
                icon="diagram-project"
                class="px-4 text-4xl cursor-pointer inline-block"
                @click="$emit('generateDoc')"
              /><br />
              <span class="text-sm mt-0">Generieren</span>
            </div>
            <div class="inline-block ml-2">
              <font-awesome-icon
                icon="upload"
                class="px-4 text-4xl cursor-pointer inline-block"
                @click="$emit('addFile', category)"
              /><br />
              <span class="text-sm mt-0">Hochladen</span>
            </div>
          </div>
        </div>
      </div>
      <div v-for="file in files" :key="file" class="inline-block px-4">
        <div
          class="
            rounded-lg
            shadow-lg
            max-w-96
            bg-white
            p-2
            text-center
            inline-block
          "
        >
          <span class="font-bold text-xl">{{ file.originalName }}</span>
          <div class="mt-4">
            <font-awesome-icon
              icon="eye"
              class="px-4 text-2xl cursor-pointer inline-block"
              @click="$emit('viewFile', file)"
            /><font-awesome-icon
              v-if="file.generated"
              icon="file-pen"
              class="px-4 text-2xl cursor-pointer inline-block"
              @click="$emit('editFile', file)"
            />
            <font-awesome-icon
              v-if="file.generated"
              icon="copy"
              class="px-4 text-2xl cursor-pointer inline-block"
              @click="$emit('copyFile', file)"
            /><font-awesome-icon
              icon="download"
              class="px-4 text-2xl cursor-pointer inline-block"
              @click="download(file)"
            /><font-awesome-icon v-if="!file.application"
              icon="trash"
              class="px-4 text-2xl cursor-pointer inline-block"
              @click="deleteFile(file)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/API/Private";

export default {
  emits: ["addFile", "viewFile", "generateDoc", "editFile", "deletedFile", "copyFile"],
  props: {
    files: Array,
    category: String,
    generate: Boolean,
  },
  data() {
    return {
      details: {},
      errors: {},
    };
  },
  methods: {
    deleteFile(file) {
      let vm = this;
      API.deleteFile(file.id, (res) => {
        vm.$emit("deletedFile");
      });
    },
    download(file) {
      window.open(
        process.env.VUE_APP_PRIVATE_URL +
          "/files/static/" +
          file.id +
          "?download",
        "_blank"
      );
    },
  },
};
</script>